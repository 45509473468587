import React, { useEffect, useState } from "react";
import AOS from "aos";
import { Carousel, Container, Row, Col, Card } from "react-bootstrap";
import "../styles/inicio.css";
import "../../src/index.css";
import "aos/dist/aos.css";

const carouselItems = [
  {
    src: "/imgs/car1.jpg",
    alt: "Primera imagen",
  },
  {
    src: "/imgs/car2.jpg",
    alt: "Segunda imagen",
  },
  {
    src: "/imgs/car3.jpg",
    alt: "Tercera imagen",
  },
];

const products = [
  {
    id: 1,
    title: "Disney+",
    description: `
      Accede a una vasta colección de clásicos y nuevas producciones de Disney+, Pixar, Marvel, Star Wars y National Geographic. Ideal para familias y fanáticos de la fantasía.
      Ofrecemos tanto el plan estándar como el plan premium.
    `,
    image: "/imgs/disney.jpg",
  },
  {
    id: 3,
    title: "Prime Video",
    description: `Explora miles de películas y series, incluyendo Amazon Originals, con Prime Video. Un mundo de entretenimiento al alcance de un click.
       Las pantallas de Prime Video son renovables.`,
    image: "/imgs/primevideo.jpg",
  },
  {
    id: 4,
    title: "Vix+",
    description:
      "Sumérgete en lo mejor del entretenimiento latino con Vix+. Desde telenovelas hasta películas, vive historias apasionantes y únicas.",
    image: "/imgs/logoVix.jpg",
  },
  {
    id: 7,
    title: "Spotify Premium",
    description:
      "Escucha tu música favorita sin interrupciones, descarga tus playlists y disfruta de una experiencia de audio sin igual con Spotify Premium.",
    image: "/imgs/logoSpotify.jpg",
  },
  {
    id: 5,
    title: "Paramount+",
    description:
      "Accede a un extenso catálogo de series, películas y deportes en vivo. Paramount+ es tu puerta de entrada a un universo de entretenimiento diverso.",
    image: "/imgs/logoParamount.jpg",
  },

  {
    id: 6,
    title: "YouTube Premium",
    description:
      "Disfruta de YouTube sin anuncios, con reproducción en segundo plano y acceso a contenido exclusivo de YouTube Premium.",
    image: "/imgs/logoYT.jpg",
  },
  {
    id: 8,
    title: "Plex",
    description:
      "Organiza y accede a tu colección personal de películas, programas de TV, música y fotos desde cualquier dispositivo con Plex.",
    image: "/imgs/plex.jpg",
  },
  {
    id: 2,
    title: "Max",
    description: `Disfruta de una variedad de contenido exclusivo de HBO, Warner Bros., y mucho más. Max ofrece entretenimiento de calidad premium para todos los gustos.
      Puedes encontrar plan estándar o plan platino. Puedes renovar la misma pantalla mes a mes.`,
    image: "/imgs/max.jpg",
  },
  {
    id: 9,
    title: "Crunchyroll",
    description: `Explora una amplia gama de anime, desde los clásicos hasta los nuevos estrenos, con Crunchyroll. El paraíso del entretenimiento japonés.
      La mayoría de las pantallas de Crunchyroll se pueden renovar.`,
    image: "/imgs/logoCrunchyroll.jpg",
  },
];

const Inicio = () => {
  const [productsToShow, setProductsToShow] = useState(products);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
      if (window.innerWidth <= 768) {
        setProductsToShow(products.slice(0, -5));
      } else {
        setProductsToShow(products);
      }

      AOS.init({
        duration: window.innerWidth <= 768 ? 500 : 700,
        once: true,
        disable: window.innerWidth <= 768,
      });
      AOS.refresh();
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <Container className="mt-5">
      <Carousel className="my-carousel">
        {carouselItems.map((item, index) => (
          <Carousel.Item key={index}>
            <img className="d-block w-100" src={item.src} alt={item.alt} />
          </Carousel.Item>
        ))}
      </Carousel>

      <Row className="my-5" data-aos="fade-up">
        <Col>
          <h3>Sobre Instashop</h3>
          <p className="pInfo">
            Bienvenido a nuestra página de ventas <strong>Instashop</strong>,
            ofrecemos cuentas para las plataformas más populares y pines legales
            para las mismas. Nos dedicamos a ofrecer productos de la más alta
            calidad y un servicio excepcional para garantizar la mejor
            experiencia posible para nuestros clientes. Contamos con una{" "}
            <a
              href="https://codeinstashop.online/"
              target="_blank"
              rel="noopener noreferrer"
              style={{
                color: "gold",
                fontWeight: "bold",
                textDecoration: "none",
              }}
            >
              página para códigos
            </a>{" "}
            automatizada, Las recargas las manejamos con nuestro chat de
            recargas desde las 8:00 a.m. a 22:00 p.m. Con nosotros, siempre
            tendrás acceso a lo último en entretenimiento digital, con seguridad
            y confianza.
          </p>
        </Col>
      </Row>

      <Row data-aos="fade-up" className="gray">
        {productsToShow.map((product) => (
          <Col md={4} key={product.id} data-aos="fade-up">
            <Card>
              <Card.Img variant="top" src={product.image} />
              <Card.Body>
                <Card.Title>{product.title}</Card.Title>
                <Card.Text>
                  {isMobile
                    ? product.description.substring(0, 81) + "..."
                    : product.description}
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>

      {isMobile ? (
        <Row className="align-items-center my-5" data-aos="fade-up">
          <Col md={12}>
            <img
              src="/imgs/mobile.jpg"
              alt="Descripción de la imagen para móviles"
              className="img-fluid"
            />
          </Col>
        </Row>
      ) : (
        <Row className="align-items-center my-5" data-aos="fade-up">
          <Col md={6}>
            <img
              src="/imgs/temor.jpg"
              alt="Descripción de la imagen"
              className="img-fluid equal-height"
            />
          </Col>
          <Col md={6}>
            <img
              src="/imgs/compra.jpg"
              alt="Descripción de la imagen"
              className="img-fluid equal-height"
            />
          </Col>
        </Row>
      )}
    </Container>
  );
};

export default Inicio;
