// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin-top: 0;
  padding: 0;
  font-family: "Times New Roman", Times, serif;
  background-color: #fff;
  color: #fff;
  
}
/*143D59*/

h1{
  font-size: 28px;
}

ul li{
  font-size: 22px;
}



`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,UAAU;EACV,4CAA4C;EAC5C,sBAAsB;EACtB,WAAW;;AAEb;AACA,SAAS;;AAET;EACE,eAAe;AACjB;;AAEA;EACE,eAAe;AACjB","sourcesContent":["body {\n  margin-top: 0;\n  padding: 0;\n  font-family: \"Times New Roman\", Times, serif;\n  background-color: #fff;\n  color: #fff;\n  \n}\n/*143D59*/\n\nh1{\n  font-size: 28px;\n}\n\nul li{\n  font-size: 22px;\n}\n\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
