import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "../styles/navbar.css";
import logo from "../imgs/logo.jpg";
import { AuthContext } from "../App";
import Dropdown from "react-bootstrap/Dropdown";
import { jwtDecode } from "jwt-decode";
import "bootstrap/dist/css/bootstrap.min.css";

const Navbar = () => {
  const { auth, setAuth } = useContext(AuthContext);
  const [username, setUsername] = useState("Usuario");
  const [esAdmin, setEsAdmin] = useState(false);
  const [saldo, setSaldo] = useState(0);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const formatearPrecio = (precio) => {
    return parseFloat(precio).toLocaleString("es-ES", {
      style: "currency",
      currency: "COP",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
  };

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);

    window.addEventListener("resize", handleResize);

    if (auth) {
      try {
        const decodedToken = jwtDecode(auth);
        const nombreCompleto = decodedToken.nombre_usuario || "Usuario";
        const primerNombre = nombreCompleto.split(" ")[0];
        setUsername(primerNombre);
        setEsAdmin(decodedToken.es_admin || false);
        setSaldo(decodedToken.saldo || 0);
      } catch (error) {
        console.error("Error decoding token:", error);
        setAuth(null);
      }
    }

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [auth, setAuth]);

  const handleLogout = () => {
    localStorage.removeItem("token");
    setAuth(null);
    window.location.href = "/login";
  };

  return (
    <>
      <nav className="navbar-top">
        <div className="navbar-top-container">
          <div className="navbar-top-actions">
            <Link to="/contacto" className="navbar-top-link">
              Contacto
            </Link>
            <Link 
              to={!isMobile ? "/comprarSaldo" : "#"} 
              className="navbar-top-link saldo"
              onClick={(e) => isMobile && e.preventDefault()}
            >
              {formatearPrecio(saldo)}
              <i className="bi bi-currency-dollar"></i>
            </Link>
            {auth && (
              <Dropdown>
                <Dropdown.Toggle
                  variant="secondary"
                  id="dropdown-basic"
                  className="dropdown-toggle-custom2"
                >
                  {username}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item as={Link} to="/cuenta">
                    Cuenta
                  </Dropdown.Item>
                  <Dropdown.Item onClick={handleLogout}>
                    Cerrar Sesión
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            )}
          </div>
        </div>
      </nav>

      <nav className="navbar">
        <div className="navbar-container">
          <Link to="/" className="navbar-logo">
            <img src={logo} alt="Logo" className="navbar-logo-img" />
          </Link>
          <ul className="navbar-menu">
            <li className="navbar-item">
              <Link to="/" className="navbar-link">
                Inicio
              </Link>
            </li>
            {esAdmin && (
              <>
                <li className="navbar-item">
                  <Link to="/usuarios" className="navbar-link">
                    Usuarios
                  </Link>
                </li>
                <li className="navbar-item">
                  <Link to="/ventas" className="navbar-link">
                    Ventas
                  </Link>
                </li>
              </>
            )}
            <li className="navbar-item">
              <Link to="/productos" className="navbar-link">
                Productos
              </Link>
            </li>
            <li className="navbar-item">
              <Link to="/detalle-ventas" className="navbar-link">
                Detalles Ventas
              </Link>
            </li>
            <li className="navbar-item">
              <Dropdown>
                <Dropdown.Toggle
                  variant="secondary"
                  id="dropdown-basic"
                  className="dropdown-toggle-custom responsive-dropdown-toggle"
                >
                  Más Opciones
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item as={Link} to="/referidos-comisiones">
                    Referidos
                  </Dropdown.Item>
                  <Dropdown.Item as={Link} to="/ranking">
                    Ranking
                  </Dropdown.Item>
                  {esAdmin && (
                    <Dropdown.Item as={Link} to="/modificar-cuentas">
                      Modificar Cuentas
                    </Dropdown.Item>
                  )}
                </Dropdown.Menu>
              </Dropdown>
            </li>
          </ul>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
