import React, { useState } from "react";

const URL = process.env.REACT_APP_URL_BACKEND;

const SubirCuenta = () => {
  const [email, setEmail] = useState("");
  const [contrasena, setContrasena] = useState("");
  const [perfil, setPerfil] = useState("P1");

  const handleSubmit = (e) => {
    e.preventDefault();

    const nuevaCuenta = {
      email_cuenta: email,
      contrasena,
      perfil_disponible: perfil,
    };

    fetch(`${URL}/subir-cuenta`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(nuevaCuenta),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Cuenta subida:", data);
      })
      .catch((error) => console.error("Error:", error));
  };

  return (
    <div className="subir-cuenta-container">
      <form onSubmit={handleSubmit}>
        <div>
          <label>Email de la cuenta:</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div>
          <label>Contraseña:</label>
          <input
            type="password"
            value={contrasena}
            onChange={(e) => setContrasena(e.target.value)}
            required
          />
        </div>
        <div>
          <label>Perfil:</label>
          <select
            value={perfil}
            onChange={(e) => setPerfil(e.target.value)}
            required
          >
            <option value="P1">P1</option>
            <option value="P2">P2</option>
            <option value="P3">P3</option>
            <option value="P4">P4</option>
            <option value="P5">P5</option>
          </select>
        </div>
        <button type="submit">Subir Cuenta</button>
      </form>
    </div>
  );
};

export default SubirCuenta;
