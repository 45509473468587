import React from "react";
import Swal from "sweetalert2";
import { useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import "../styles/comprarSaldo.css";

const ComprarSaldo = () => {
  useEffect(() => {
    Swal.fire({
      icon: "info",
      title: "Recomendación",
      text: `Las recargas deben ser mínimo de 5.000 pesos al chat de recargas dentro de los horarios establecidos.`,
      confirmButtonText: "Aceptar",
    });
  }, []);

  return (
    <div className="containerSaldo">
      <div className="containerComprar">
        <Row className="align-items-center my-5" data-aos="fade-up">
          <Col md={6} className="pF">
            <div className="payment-info">
              <h2>💰 MEDIOS DE PAGO 💰</h2>
              <br></br>
              <p>
                REALIZAR EL PAGO Y ENVIAR COMPROBANTE A CHAT DE RECARGAS PARA
                ASIGNAR MANUALMENTE EL SALDO
              </p>
              <p>
                Nequi: 3024824806 - 3132175075 Diego Morales - Mónica Cifuentes
              </p>
              <p>
                Daviplata: 3024824806 - 3132175075 Diego Morales - Mónica
                Cifuentes
              </p>
              <p>Ahorro a la mano: 03132175075 Mónica Cifuentes</p>
              <p>Bancolombia (cuenta de ahorros): 912-569535-74</p>
              <p>ID BINANCE: 782452666 instashop_</p>
              <p>
                NO ESCRIBIR NADA EN DESCRIPCION DE PAGO, POR FAVOR ENVIAR EL
                COMPROBANTE LEGIBLE CON FECHA Y HORA
              </p>
              <p>Chat de recargas: 3249847760</p>
              <p>Chat de garantías: 3024824806</p>
            </div>
          </Col>
          <Col md={6}>
            <img
              src="/imgs/pagosPag.jpg"
              alt="Descripción de la imagen"
              className="iF"
            />
          </Col>
        </Row>
      </div>
      <div className="containerCondiciones">
        <Row className="align-items-start my-5" data-aos="fade-up">
          <Col md={6}>
            <div className="terms-info">
              <h2>Términos y Condiciones</h2>
              <p>
                Bienvenido a Instashop. Al acceder y utilizar nuestros
                servicios, usted acepta cumplir con los siguientes términos y
                condiciones. Por favor, léalos detenidamente.
              </p>
              <h3>1. Descripción del Servicio</h3>
              <p>
                Instashop proporciona acceso a productos de plataformas de
                streaming. Si adquieres un producto de tipo PANTALLA, dicha
                compra es para un único dispositivo.
              </p>
              <h3>2. Uso de Productos</h3>
              <ul>
                <li>
                  <strong>Uso Exclusivo:</strong> Al comprar un producto, como
                  una pantalla, está destinado a un solo dispositivo. No se
                  permite el uso simultáneo en más de un dispositivo. La
                  responsabilidad del manejo y cuidado de las cuentas completas
                  vendidas en nuestra página depende de su comprador.
                </li>
                <li>
                  <strong>Prohibición de Compra en Amazon:</strong> Está
                  prohibido realizar compras o pedidos de productos a través de
                  la plataforma de Amazon en nuestros productos de Cuenta
                  Completa o Pantalla de Amazon Prime.
                </li>
                <li>
                  <strong>Modificación de Perfiles y Contraseñas:</strong> No se
                  permite cambiar los nombres de perfil ni las contraseñas de
                  las pantallas adquiridas.
                </li>
              </ul>
            </div>
          </Col>
          <Col md={6}>
            <div className="terms-info">
              <h3>3. Reporte de Problemas</h3>
              <ul>
                <li>
                  <strong>Informar Problemas:</strong> Cualquier problema o
                  incidencia con el producto adquirido debe ser reportado
                  adjuntando la información completa de la cuenta comprada en el
                  chat de soporte.
                </li>
                <li>
                  <strong>Historial de Compras:</strong> Todos los detalles de
                  la compra serán enviados al correo electrónico asociado con la
                  cuenta en el momento de la compra.
                </li>
              </ul>
              <h3>4. Garantía</h3>
              <p>
                La garantía para los productos adquiridos puede variar entre 24
                y 48 horas a partir del momento del reporte realizado.
              </p>
              <h3>5. Suspensión del Servicio</h3>
              <p>
                Cualquier incumplimiento de las reglas establecidas llevará a la
                suspensión del servicio sin derecho a reembolso.
              </p>
              <h3>6. Modificaciones</h3>
              <p>
                Instashop se reserva el derecho de modificar estos términos y
                condiciones en cualquier momento. Cualquier cambio será
                publicado en nuestra página y canal de difusión.
              </p>
              <h3>7. Contacto</h3>
              <p>
                Para cualquier duda o consulta, por favor, póngase en contacto
                con nosotros a través de nuestros medios de comunicación dentro
                de nuestro horario laboral establecido.
              </p>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default ComprarSaldo;
