import "../src/styles/baseline.css";
import "./App.css";
import React, { useState, createContext, useContext, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Navbar from "./components/navbar";
import Inicio from "./components/inicio";
import Usuarios from "./components/usuarios";
import Productos from "./components/productos";
import Ventas from "./components/ventas";
import DetalleVentas from "./components/detalleVentas";
import ReferidosComisiones from "./components/referidosComisiones";
import ContactUs from "./components/contactus";
import Register from "./components/register";
import Login from "./components/login";
import SubirCuenta from "./components/SubirCuenta";
import ResetPass from "./components/resetPass";
import { ToastContainer } from "react-toastify";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import ComprarSaldo from "./components/comprarSaldo";
import Footer from "./components/footer";
import Ranking from "./components/ranking";
import ModificarCuentas from "./components/modificarCuentas";
import Cuenta from "./components/cuenta";

export const AuthContext = createContext(null);

const publicRoutes = ["/login", "/register", "/resetPass"];

const PrivateRoute = ({ element }) => {
  const { auth } = useContext(AuthContext);
  return auth ? element : <Navigate to="/login" />;
};

const PublicRoute = ({ element, path }) => {
  const { auth } = useContext(AuthContext);
  if (auth && publicRoutes.includes(path)) {
    return element;
  }
  return !auth ? element : <Navigate to="/" />;
};

function App() {
  const [auth, setAuth] = useState(localStorage.getItem("token"));

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      fetch("/api/protected", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => res.json())
        .then((data) => setAuth({ ...data, token }));
    }
  }, []);

  return (
    <AuthContext.Provider value={{ auth, setAuth }}>
      <Router>
        <Routes>
          <Route
            path="/login"
            element={<PublicRoute element={<Login />} path="/login" />}
          />
          <Route
            path="/register"
            element={<PublicRoute element={<Register />} path="/register" />}
          />
          <Route
            path="/resetPass"
            element={<PublicRoute element={<ResetPass />} path="/resetPass" />}
          />
          <Route
            path="/"
            element={
              auth ? (
                <>
                  <Navbar />
                  <PrivateRoute element={<Inicio />} />
                </>
              ) : (
                <Navigate to="/login" />
              )
            }
          />
          <Route
            path="/usuarios"
            element={
              auth ? (
                <>
                  <Navbar />
                  <PrivateRoute element={<Usuarios />} />
                </>
              ) : (
                <Navigate to="/login" />
              )
            }
          />
          <Route
            path="/productos"
            element={
              auth ? (
                <>
                  <Navbar />
                  <PrivateRoute element={<Productos />} />
                </>
              ) : (
                <Navigate to="/login" />
              )
            }
          />
          <Route
            path="/ventas"
            element={
              auth ? (
                <>
                  <Navbar />
                  <PrivateRoute element={<Ventas />} />
                </>
              ) : (
                <Navigate to="/login" />
              )
            }
          />
          <Route
            path="/detalle-ventas"
            element={
              auth ? (
                <>
                  <Navbar />
                  <PrivateRoute element={<DetalleVentas />} />
                </>
              ) : (
                <Navigate to="/login" />
              )
            }
          />
          <Route
            path="/referidos-comisiones"
            element={
              auth ? (
                <>
                  <Navbar />
                  <PrivateRoute element={<ReferidosComisiones />} />
                </>
              ) : (
                <Navigate to="/login" />
              )
            }
          />
          <Route
            path="/subir-cuentas"
            element={
              auth ? (
                <>
                  <Navbar />
                  <PrivateRoute element={<SubirCuenta />} />
                </>
              ) : (
                <Navigate to="/login" />
              )
            }
          />
          <Route
            path="/comprarSaldo"
            element={
              auth ? (
                <>
                  <Navbar />
                  <PrivateRoute element={<ComprarSaldo />} />
                </>
              ) : (
                <Navigate to="/login" />
              )
            }
          />
          <Route
            path="/contacto"
            element={
              auth ? (
                <>
                  <Navbar />
                  <PrivateRoute element={<ContactUs />} />
                </>
              ) : (
                <Navigate to="/login" />
              )
            }
          />
          <Route
            path="/ranking"
            element={
              auth ? (
                <>
                  <Navbar />
                  <PrivateRoute element={<Ranking />} />
                </>
              ) : (
                <Navigate to="/login" />
              )
            }
          />
          <Route
            path="/modificar-cuentas"
            element={
              auth ? (
                <>
                  <Navbar />
                  <PrivateRoute element={<ModificarCuentas />} />
                </>
              ) : (
                <Navigate to="/login" />
              )
            }
          />
          <Route
            path="/cuenta"
            element={
              auth ? (
                <>
                  <Navbar />
                  <PrivateRoute element={<Cuenta />} />
                </>
              ) : (
                <Navigate to="/login" />
              )
            }
          />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
        <Footer />
      </Router>
      <ToastContainer />
    </AuthContext.Provider>
  );
}

export default App;
