// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
::-webkit-scrollbar-thumb {
  background-color: rgba(255, 213, 0, 0.651);
  border-radius: 10px; 
  border: 3px solid rgba(0, 0, 0, 0);
}

*::-webkit-scrollbar {
  width: 2px; 
}

*::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0); 
}

*::-webkit-scrollbar-thumb {
  background-color: rgba(255, 213, 0, 0.651); 
  border-radius: 10px; 
  border: 3px solid rgba(0, 0, 0, 0); 
}


body {
  background: linear-gradient(to bottom, #dbd5d5, #143d59);
  font-family: Arial, Helvetica, sans-serif;
  min-height: 100dvh;
}

h1, h2, h3, h4 {
  text-wrap: balance;
}

p{
  text-wrap: pretty;
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":";AACA;EACE,0CAA0C;EAC1C,mBAAmB;EACnB,kCAAkC;AACpC;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,4BAA4B;AAC9B;;AAEA;EACE,0CAA0C;EAC1C,mBAAmB;EACnB,kCAAkC;AACpC;;;AAGA;EACE,wDAAwD;EACxD,yCAAyC;EACzC,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,iBAAiB;AACnB","sourcesContent":["\n::-webkit-scrollbar-thumb {\n  background-color: rgba(255, 213, 0, 0.651);\n  border-radius: 10px; \n  border: 3px solid rgba(0, 0, 0, 0);\n}\n\n*::-webkit-scrollbar {\n  width: 2px; \n}\n\n*::-webkit-scrollbar-track {\n  background: rgba(0, 0, 0, 0); \n}\n\n*::-webkit-scrollbar-thumb {\n  background-color: rgba(255, 213, 0, 0.651); \n  border-radius: 10px; \n  border: 3px solid rgba(0, 0, 0, 0); \n}\n\n\nbody {\n  background: linear-gradient(to bottom, #dbd5d5, #143d59);\n  font-family: Arial, Helvetica, sans-serif;\n  min-height: 100dvh;\n}\n\nh1, h2, h3, h4 {\n  text-wrap: balance;\n}\n\np{\n  text-wrap: pretty;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
