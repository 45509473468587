import React from "react";
import "../styles/footer.css";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container">
        <p>&copy; 2024 Instashop. Todos los derechos reservados. </p>
        <div className="footer-links">
          <a
            href="https://www.instagram.com/instashop_morales?igsh=MWdpbWNwdDY5Z2l4Yg=="
            target="_blank"
            rel="noopener noreferrer"
          >
            Instagram
          </a>
          <a
            href="https://wa.me/3024824806"
            target="_blank"
            rel="noopener noreferrer"
          >
            WhatsApp
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
