import React, { useEffect, useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import "../styles/cuenta.css";
import { Link } from "react-router-dom";

const URL = process.env.REACT_APP_URL_BACKEND;

const InfoCuenta = () => {
  const [userInfo, setUserInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const response = await axios.get(`${URL}/api/cuenta`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
        setUserInfo(response.data);
      } catch (error) {
        setError("Error al obtener la información de la cuenta");
      } finally {
        setLoading(false);
      }
    };

    fetchUserInfo();
  }, []);

  const handlePasswordChange = async (e) => {
    e.preventDefault();

    if (newPassword !== confirmPassword) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Las contraseñas no coinciden',
      });
      return;
    }

    if (newPassword === currentPassword) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'La nueva contraseña no puede ser la misma que la actual',
      });
      return;
    }

    try {
      const response = await axios.post(`${URL}/api/cambiar-contrasena`, {
        currentPassword,
        newPassword,
        confirmPassword,
      }, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      Swal.fire({
        icon: 'success',
        title: 'Éxito',
        text: response.data.message,
      });

      setCurrentPassword("");
      setNewPassword("");
      setConfirmPassword("");
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: error.response?.data?.message || 'Error al actualizar la contraseña',
      });
    }
  };

  if (loading) return <p>Cargando...</p>;
  if (error) return <p>{error}</p>;

  return (
    <div className="info-cuenta-wrapper">
      <div className="info-cuenta-container">
        <div className="header-container">
          <h1>INFORMACIÓN DE LA CUENTA, {userInfo.nombre_usuario}</h1>
          <div className="saldo">
            <h2>Saldo: ${Number(userInfo.saldo).toFixed(0)}</h2>
          </div>
        </div>
        <div className="info-details">
          <p>
            <strong>Fecha de Registro:</strong>{" "}
            {new Date(userInfo.fecha_registro).toLocaleDateString()}
          </p>
          <p>
            <strong>Número de Celular:</strong> {userInfo.numero_celular}
          </p>
          <p>
            <strong>Correo electrónico:</strong> {userInfo.email}
          </p>
          <p>
            <strong>Contraseña:</strong> {userInfo.password || "******"}
          </p>
          <p>
            <strong>Id referido por:</strong> {userInfo.id_referido_por}
          </p>
        </div>
        <div className="recover-container">
          <div className="recover-text">
            <p>
              Puedes cambiar tu contraseña en el formulario o usando la
              siguiente opción{" "}
              <Link to="/resetPass" className="reset-password-link">
                ¿Olvidaste tu contraseña?
              </Link>
            </p>
            <hr className="linea" />
            <p>
              Puedes invitar a un conocido y recibir una comisión por cada venta
              de dicho referido, para mayor información dirígete a{" "}
              <Link to="/referidos-comisiones" className="referidos-Comisiones">
                Referidos y Comisiones
              </Link>
            </p>
            <hr className="linea" />
          </div>
          <div className="recover-form">
            <form onSubmit={handlePasswordChange}>
              <label htmlFor="current-password">Contraseña Actual:</label>
              <input
                type="password"
                id="current-password"
                name="current-password"
                placeholder="Contraseña actual"
                value={currentPassword}
                onChange={(e) => setCurrentPassword(e.target.value)}
                required
              />
              <label htmlFor="new-password">Nueva Contraseña:</label>
              <input
                type="password"
                id="new-password"
                name="new-password"
                placeholder="Nueva contraseña"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                required
              />
              <label htmlFor="confirm-password">Confirmar Contraseña:</label>
              <input
                type="password"
                id="confirm-password"
                name="confirm-password"
                placeholder="Confirma tu nueva contraseña"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
              />
              <button type="submit">Actualizar Contraseña</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InfoCuenta;
