// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer {
    background-color: #bcb1b172;
    color: #000000;
    text-align: center;
    padding: 20px 0;
    position: relative;
    bottom: 0;
    width: 100%;
  }
  
  .footer .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
  }
  
  .footer p {
    margin: 0;
  }
  
  .footer-links {
    margin-top: 10px;
  }
  
  .footer-links a {
    color: #7ebce4;
    text-decoration: none;
    font-size: 20px;
    margin: 0 10px;
  }
  
  .footer-links a:hover {
    color: #fff;
    text-decoration: underline;
  }
  
  @media (max-width: 768px) {
    .footer-links a{
      font-size: 18px;
    }
  }
  `, "",{"version":3,"sources":["webpack://./src/styles/footer.css"],"names":[],"mappings":"AAAA;IACI,2BAA2B;IAC3B,cAAc;IACd,kBAAkB;IAClB,eAAe;IACf,kBAAkB;IAClB,SAAS;IACT,WAAW;EACb;;EAEA;IACE,iBAAiB;IACjB,cAAc;IACd,eAAe;EACjB;;EAEA;IACE,SAAS;EACX;;EAEA;IACE,gBAAgB;EAClB;;EAEA;IACE,cAAc;IACd,qBAAqB;IACrB,eAAe;IACf,cAAc;EAChB;;EAEA;IACE,WAAW;IACX,0BAA0B;EAC5B;;EAEA;IACE;MACE,eAAe;IACjB;EACF","sourcesContent":[".footer {\n    background-color: #bcb1b172;\n    color: #000000;\n    text-align: center;\n    padding: 20px 0;\n    position: relative;\n    bottom: 0;\n    width: 100%;\n  }\n  \n  .footer .container {\n    max-width: 1200px;\n    margin: 0 auto;\n    padding: 0 20px;\n  }\n  \n  .footer p {\n    margin: 0;\n  }\n  \n  .footer-links {\n    margin-top: 10px;\n  }\n  \n  .footer-links a {\n    color: #7ebce4;\n    text-decoration: none;\n    font-size: 20px;\n    margin: 0 10px;\n  }\n  \n  .footer-links a:hover {\n    color: #fff;\n    text-decoration: underline;\n  }\n  \n  @media (max-width: 768px) {\n    .footer-links a{\n      font-size: 18px;\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
