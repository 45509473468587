import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import "../styles/usuarios.css";

const URL = process.env.REACT_APP_URL_BACKEND;

const Usuarios = () => {
  const [usuarios, setUsuarios] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [editUser, setEditUser] = useState(null);
  const usuariosPerPage = 10;

  useEffect(() => {
    const fetchUsuarios = async () => {
      try {
        const response = await fetch(`${URL}/api/usuarios`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });

        if (!response.ok) {
          throw new Error("No se pudo obtener la lista de usuarios");
        }

        const data = await response.json();
        setUsuarios(data);
      } catch (error) {
        console.error("Error al obtener los usuarios:", error);
      }
    };

    fetchUsuarios();
  }, []);

  const handleEditClick = (usuario) => {
    setEditUser(usuario);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    const updatedValue =
      name === "es_admin" ? (value === "Sí" ? true : false) : value;

    setEditUser({
      ...editUser,
      [name]: updatedValue,
    });
  };

  const handleSave = async () => {
    try {
      const response = await fetch(
        `${URL}/api/usuarios/${editUser.id_usuario}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify(editUser),
        }
      );

      if (!response.ok) {
        throw new Error("No se pudo actualizar el usuario");
      }

      const updatedUser = await response.json();
      setUsuarios(
        usuarios.map((user) =>
          user.id_usuario === updatedUser.id_usuario ? updatedUser : user
        )
      );
      setEditUser(null);

      Swal.fire({
        icon: "success",
        title: "Usuario actualizado",
        text: `El usuario ${editUser.nombre_usuario} se ha actualizado correctamente.`,
        showConfirmButton: false,
        timer: 1500,
      });
    } catch (error) {
      console.error("Error al guardar el usuario:", error);

      Swal.fire({
        icon: "error",
        title: "Error",
        text: "No se pudo actualizar el usuario. Inténtalo nuevamente.",
      });
    }
  };

  const filteredUsuarios = usuarios.filter((usuario) => {
    const lowerCaseTerm = searchTerm.toLowerCase();

    const nombreUsuario = usuario.nombre_usuario.toLowerCase();
    const email = usuario.email.toLowerCase();
    const numeroCelular = usuario.numero_celular.toLowerCase();
    const idReferidoPor = usuario.id_referido_por
      ? usuario.id_referido_por.toString().toLowerCase()
      : "";
    const esAdmin = (usuario.es_admin ? "Si" : "No").toLowerCase();
    const saldo = usuario.saldo.toString().toLowerCase();

    return (
      nombreUsuario.includes(lowerCaseTerm) ||
      email.includes(lowerCaseTerm) ||
      numeroCelular.includes(lowerCaseTerm) ||
      idReferidoPor.includes(lowerCaseTerm) ||
      esAdmin.includes(lowerCaseTerm) ||
      saldo.includes(lowerCaseTerm)
    );
  });

  const indexOfLastUsuario = currentPage * usuariosPerPage;
  const indexOfFirstUsuario = indexOfLastUsuario - usuariosPerPage;
  const currentUsuarios = filteredUsuarios.slice(
    indexOfFirstUsuario,
    indexOfLastUsuario
  );

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className="usuarios-wrapper">
      <div className="usuarios-container">
        <div className="search-bar">
          <h2>Usuarios Registrados</h2>
          <input
            type="text"
            placeholder="Buscar..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>

        {editUser && (
          <div className="edit-form">
            <h3 className="h3Form">Editar Usuario</h3>
            <label>
              Nombre:
              <input
                type="text"
                name="nombre_usuario"
                value={editUser.nombre_usuario}
                onChange={handleChange}
              />
            </label>
            <label>
              Email:
              <input
                type="email"
                name="email"
                value={editUser.email}
                readOnly
              />
            </label>
            <label>
              Teléfono:
              <input
                type="text"
                name="numero_celular"
                value={editUser.numero_celular}
                onChange={handleChange}
              />
            </label>
            <label>
              Saldo:
              <input
                type="number"
                name="saldo"
                value={editUser.saldo}
                onChange={handleChange}
              />
            </label>
            <label>
              Referido Por:
              <input
                type="text"
                name="id_referido_por"
                value={editUser.id_referido_por || ""}
                onChange={handleChange}
              />
            </label>
            <label>
              Admin:
              <select
                name="es_admin"
                value={editUser.es_admin ? "Sí" : "No"}
                onChange={handleChange}
              >
                <option value="Sí">Sí</option>
                <option value="No">No</option>
              </select>
            </label>
            <button className="guardarForm" onClick={handleSave}>
              Guardar
            </button>
            <button className="cancelarForm" onClick={() => setEditUser(null)}>
              Cancelar
            </button>
          </div>
        )}

        <table>
          <thead>
            <tr>
              <th>ID</th>
              <th>Nombre</th>
              <th>Email</th>
              <th>Teléfono</th>
              <th>Fecha de Registro</th>
              <th>Referido Por</th>
              <th>Saldo</th>
              <th>Proveedor</th>
              <th>Google ID</th>
              <th>Admin</th>
              <th>V</th>
              <th>Editar</th>
            </tr>
          </thead>
          <tbody>
            {currentUsuarios.map((usuario) => (
              <tr key={usuario.id_usuario}>
                <td>{usuario.id_usuario}</td>
                <td>{usuario.nombre_usuario}</td>
                <td>{usuario.email}</td>
                <td>{usuario.numero_celular}</td>
                <td>{new Date(usuario.fecha_registro).toLocaleDateString()}</td>
                <td>{usuario.id_referido_por || "N/A"}</td>
                <td>{usuario.saldo}</td>
                <td>{usuario.proveedor_autenticacion}</td>
                <td>{usuario.google_id || "N/A"}</td>
                <td>{usuario.es_admin ? "Sí" : "No"}</td>
                <td>{usuario.email_verificado ? "Sí" : "No"}</td>
                <td>
                  <button
                    className="editar-button"
                    onClick={() => handleEditClick(usuario)}
                  >
                    Editar
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <div className="pagination">
          {Array.from(
            { length: Math.ceil(filteredUsuarios.length / usuariosPerPage) },
            (_, index) => (
              <button
                key={index + 1}
                onClick={() => paginate(index + 1)}
                className={currentPage === index + 1 ? "active" : ""}
              >
                {index + 1}
              </button>
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default Usuarios;
