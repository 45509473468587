import React from "react";
import "../styles/contactus.css";

const ContactUs = () => {
  return (
    <div className="contactus-container">
      <div className="contactus-left">
        <h1>Contáctanos</h1>
        <p>
          En <strong>Instashop</strong>, nos preocupamos por tu experiencia y
          estamos aquí para ayudarte en lo que necesites. Si tienes alguna
          pregunta o necesitas asistencia, no dudes en ponerte en contacto con
          nosotros.
        </p>

        <div className="contactus-details">
          <h1>Información de Contacto</h1>
          <p>
            <strong>Chat garantías:</strong> 3024824806
          </p>
          <p>
            <strong>Chat recargas:</strong> 3249847760
          </p>
          <p>
            <strong>Chat de respaldo:</strong> 3132175075
          </p>
          <p>
            <strong>Instagram:</strong> @instashop_morales
          </p>
          <p>
            <strong>E-mail:</strong> instashopmorales@gmail.com
          </p>
        </div>
      </div>
      <div className="contactus-right">
        <h1>Servicios Adicionales</h1>
        <p>
          Además, ofrecemos una página web para códigos automáticos que puedes
          utilizar para tus servicios de streaming con nosotros. Esta
          herramienta está configurada para poder obtener codigos de Netflix,
          Disney+ y Prime Video. Puedes acceder a ella a través del siguiente
          enlace:
        </p>
        <a
          href="https://codeinstashop.online/"
          target="_blank"
          rel="noopener noreferrer"
          className="contactus-link"
        >
          https://codeinstashop.online/
        </a>
        <p>
          Cualquier duda o inquietud, puedes ponerte en contacto con nosotros.
        </p>
      </div>
    </div>
  );
};

export default ContactUs;
