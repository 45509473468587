import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import "../styles/resetPass.css";
import logo from "../imgs/logoNav.jpg";

const URL = process.env.REACT_APP_URL_BACKEND;

const ResetPass = () => {
  const [email, setEmail] = useState("");
  const navigate = useNavigate();

  const handleResetPassword = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${URL}/resetPassword`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email }),
      });
  
      if (!response.ok) {
        throw new Error("Error al enviar el correo de recuperación");
      }
  
      Swal.fire({
        icon: "success",
        title: "Correo enviado",
        text: "Revisa tu correo electrónico para recuperar tu contraseña.",
        confirmButtonColor: "#3085d6",
        confirmButtonText: "Aceptar",
      }).then(() => {
        navigate("/login");
      });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Correo incorrecto, intenta nuevamente.",
        confirmButtonColor: "#3085d6",
        confirmButtonText: "Reintentar",
      });
    }
  };
  

  return (
    <div className="containerReset">
      <div className="login-containerR">
        <div className="divImg">
          <img src={logo} alt="Logo" className="navbar-logo-img" />
        </div>
        <h2>Recuperar Contraseña en instashop</h2>
        <hr className="linea" />
        <form onSubmit={handleResetPassword} className="login-form">
          <div className="form-group">
            <label htmlFor="email">Correo electrónico</label>
            <input
              type="email"
              id="email"
              placeholder="Correo electrónico"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              autoComplete="off"
            />
          </div>
          <button type="submit" className="btn-submit">
            Enviar correo electrónico
          </button>
        </form>
        <div className="login-options">
          <p className="pColor">Revisa tu correo electrónico</p>
          <p className="pColor">
            ¿Ya la recuperaste?{" "}
            <a href="/login" className="sign-up">
              Volver a iniciar sesión en instashop
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default ResetPass;
