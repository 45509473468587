import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import axios from "axios";
import "../styles/modificarCuentas.css";

const URL = process.env.REACT_APP_URL_BACKEND;

const ModificarCuentas = () => {
  const [cuentas, setCuentas] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [editCuenta, setEditCuenta] = useState(null);
  const [categorias, setCategorias] = useState([]);
  const [productos, setProductos] = useState([]);

  const fetchCuentas = async () => {
    try {
      const response = await axios.get(`${URL}/api/cuentas-disponibles`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      setCuentas(response.data);
      setLoading(false);
    } catch (err) {
      setError("Error al obtener las cuentas disponibles");
      setLoading(false);
    }
  };

  const fetchCategorias = async () => {
    try {
      const response = await axios.get(`${URL}/categorias`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      setCategorias(response.data);
    } catch (err) {
      console.error("Error al obtener las categorías:", err);
    }
  };

  const fetchProductos = async (categoria = "") => {
    try {
      const response = await axios.get(`${URL}/productos`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        params: { categoria },
      });
      setProductos(response.data);
    } catch (err) {
      console.error("Error al obtener los productos:", err);
    }
  };

  useEffect(() => {
    fetchCuentas();
    fetchCategorias();
    fetchProductos();
  }, []);

  const handleEditClick = (cuenta) => {
    setEditCuenta(cuenta);
    if (cuenta.nombre_categoria) {
      fetchProductos(cuenta.nombre_categoria);
    } else {
      fetchProductos();
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "nombre_categoria") {
      fetchProductos(value);
      setEditCuenta((prevState) => ({
        ...prevState,
        nombre_categoria: value,
        nombre_producto: "",
      }));
    } else {
      setEditCuenta({
        ...editCuenta,
        [name]: value,
      });
    }
  };

  const handleSave = async () => {
    try {
      const response = await axios.put(
        `${URL}/api/cuentas/${editCuenta.id_cuenta}`,
        editCuenta,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.status !== 200) {
        throw new Error("No se pudo actualizar la cuenta");
      }

      const updatedCuenta = response.data.cuenta;

      setCuentas(
        cuentas.map((cuenta) =>
          cuenta.id_cuenta === updatedCuenta.id_cuenta ? updatedCuenta : cuenta
        )
      );

      setEditCuenta(null);

      Swal.fire({
        icon: "success",
        title: "Cuenta actualizada",
        html: `
          La cuenta ${editCuenta.correo} se ha actualizado correctamente.<br><br>
          <p>Para ver reflejados los cambios, actualiza la página o espera un momento.</p>
        `,
        showConfirmButton: false,
        timer: 3500,
      });
    } catch (error) {
      console.error("Error al guardar la cuenta:", error);

      Swal.fire({
        icon: "error",
        title: "Error",
        text: "No se pudo actualizar la cuenta. Inténtalo nuevamente.",
      });
    }
  };

  return (
    <div className="cuentas-wrapper">
      <div className="cuentas-container">
        <h2>Modificar Cuentas</h2>
        {loading ? (
          <p>Cargando cuentas...</p>
        ) : error ? (
          <p>{error}</p>
        ) : (
          <>
            {editCuenta && (
              <div className="edit-form">
                <h3 className="h3E">Editar Cuenta</h3>
                <label>
                  Correo:
                  <input
                    type="email"
                    name="correo"
                    value={editCuenta.correo || ""}
                    onChange={handleChange}
                  />
                </label>
                <label>
                  Contraseña:
                  <input
                    type="text"
                    name="contrasena"
                    value={editCuenta.contrasena || ""}
                    onChange={handleChange}
                  />
                </label>
                <label>
                  Categoría:
                  <select
                    name="nombre_categoria"
                    className="modifS"
                    value={editCuenta.nombre_categoria || ""}
                    onChange={(e) => e.preventDefault()}
                    onClick={(e) => e.preventDefault()}
                  >
                    <option value="">Seleccione una categoría</option>
                    {categorias.map((categoria) => (
                      <option
                        key={categoria.id_categoria}
                        value={categoria.nombre_categoria}
                      >
                        {categoria.nombre_categoria}
                      </option>
                    ))}
                  </select>
                </label>

                <label>
                  Nombre Producto:
                  <select
                    name="nombre_producto"
                    value={editCuenta.nombre_producto || ""}
                    onChange={(e) => e.preventDefault()}
                    onClick={(e) => e.preventDefault()}
                    className="modifS"
                  >
                    <option value="">Seleccione un producto</option>
                    {productos.map((producto) => (
                      <option
                        key={producto.id_producto}
                        value={producto.nombre_producto}
                      >
                        {producto.nombre_producto}
                      </option>
                    ))}
                  </select>
                </label>

                <button className="guardarForm" onClick={handleSave}>
                  Guardar
                </button>
                <button
                  className="cancelarForm"
                  onClick={() => setEditCuenta(null)}
                >
                  Cancelar
                </button>
              </div>
            )}

            <table>
              <thead>
                <tr>
                  <th>C</th>
                  <th>V</th>
                  <th>Correo</th>
                  <th>Contraseña</th>
                  <th>Estado</th>
                  <th>Nombre Producto</th>
                  <th>Nombre Perfil</th>
                  <th>Producto</th>
                  <th>Total Venta</th>
                  <th>Fecha Venta</th>
                  <th>Fecha Caducidad</th>
                  <th>Acciones</th>
                </tr>
              </thead>
              <tbody>
                {cuentas.map((cuenta) => (
                  <tr
                    key={cuenta.id_cuenta}
                    className={
                      cuenta.estado_cuenta === "Vendido"
                        ? "row-sold"
                        : "row-available"
                    }
                  >
                    <td>{cuenta.id_cuenta}</td>
                    <td>{cuenta.id_venta}</td>
                    <td>{cuenta.correo}</td>
                    <td>{cuenta.contrasena}</td>
                    <td>{cuenta.estado_cuenta}</td>
                    <td>{cuenta.nombre_producto}</td>
                    <td>{cuenta.nombre_perfil || "Cuenta Completa"}</td>
                    <td>{cuenta.tipo_producto_venta}</td>
                    <td>{cuenta.total_venta}</td>
                    <td>
                      {cuenta.fecha_venta
                        ? new Date(cuenta.fecha_venta)
                            .toISOString()
                            .split("T")[0]
                        : ""}
                    </td>
                    <td>
                      {cuenta.fecha_caducidad
                        ? new Date(cuenta.fecha_caducidad)
                            .toISOString()
                            .split("T")[0]
                        : ""}
                    </td>
                    <td>
                      <button
                        className="editar-button"
                        onClick={() => handleEditClick(cuenta)}
                      >
                        M
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </>
        )}
      </div>
    </div>
  );
};

export default ModificarCuentas;
