import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import { jwtDecode } from "jwt-decode";
import "../styles/referidosComisiones.css";

const URL = process.env.REACT_APP_URL_BACKEND;

const ReferidosComisiones = () => {
  const [comisiones, setComisiones] = useState([]);
  const [filteredComisiones, setFilteredComisiones] = useState([]);
  const [error, setError] = useState(null);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [invitationLink, setInvitationLink] = useState("");

  useEffect(() => {
    const fetchComisiones = async () => {
      try {
        const token = localStorage.getItem("token");

        if (!token) {
          throw new Error("No se encontró el token");
        }

        const decoded = jwtDecode(token);

        const response = await fetch(
          `${URL}/referidos-comisiones`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(
            `Error ${response.status}: ${
              errorData.error || "Network response was not ok"
            }`
          );
        }

        const data = await response.json();
        setComisiones(data);
        setFilteredComisiones(data);
        setError(null);
      } catch (error) {
        setError(error.message);
      }
    };

    fetchComisiones();
  }, []);

  useEffect(() => {
    const filtered = comisiones.filter(
      (comision) =>
        comision["Nombre Usuario Referente"]
          .toLowerCase()
          .includes(search.toLowerCase()) ||
        comision["Nombre Usuario Referido"]
          .toLowerCase()
          .includes(search.toLowerCase()) ||
        comision["Nombre Producto"].toLowerCase().includes(search.toLowerCase())
    );
    setFilteredComisiones(filtered);
  }, [search, comisiones]);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredComisiones.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  const handlePageChange = (event, pageNumber) => {
    event.preventDefault();
    setCurrentPage(pageNumber);
  };

  const totalGanado = filteredComisiones
    .reduce((acc, comision) => acc + parseFloat(comision["Monto Comision"]), 0)
    .toFixed(0);

  const generateInvitationLink = async () => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        throw new Error("No se encontró el token");
      }

      const response = await fetch(
        `${URL}/api/generate-invitation-link`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(`Error ${response.status}: ${errorData.message}`);
      }

      const data = await response.json();
      const shortLink = data.link.split("register?token=")[1];
      setInvitationLink(shortLink);

      Swal.fire({
        title: "Enlace de invitación generado",
        text: `Aquí está tu enlace de invitación: ${shortLink}`,
        icon: "success",
        showCancelButton: true,
        confirmButtonText: "Copiar",
        cancelButtonText: "Cerrar",
        preConfirm: () => {
          navigator.clipboard.writeText(shortLink);
        },
      });
    } catch (error) {
      setError(error.message);
    }
  };

  if (error) {
    return <div className="error-message">Error: {error}</div>;
  }

  return (
    <div className="containerReferidos">
      <div className="referidos-wrapper">
        <div className="containerR">
          <h2 className="h2C">
            ¿Cómo funcionan los referidos y las comisiones?
          </h2>
          <p>
            Los referidos permiten a los usuarios invitar a otros miembros a
            unirse a la plataforma. Por cada usuario que se registre utilizando
            su enlace de referido, el usuario que realizó la invitación recibirá
            una comisión basada en las compras realizadas por el referido. Las
            comisiones se calculan automáticamente y se reflejan en el saldo del
            usuario.
          </p>
          <p>
            Para los porcentajes de comisiones se trabajan de la siguiente
            manera:
          </p>
          <ul>
            <li>
              Las compras por cuentas completas te darán un valor del 5% del
              valor del producto comprado.
            </li>
            <li>
              Las compras por pantallas te darán un valor del 10% del valor del
              producto comprado.
            </li>
          </ul>
          <p>
            Puedes tener un máximo de dos referidos. Y al tener los dos estarías
            generando un 10% de comisión por cuentas completas y un 20% por
            pantallas en cada venta que tus referidos realicen.
          </p>
        </div>
        <div className="referidos-container">
          <h1>Comisiones por Referidos</h1>
          <input
            className="search-barComisiones"
            type="text"
            placeholder="Buscar..."
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          <p className="totalGanado">Total Ganado: ${totalGanado}</p>
          {currentItems.length === 0 ? (
            <p className="nUsuario">Ninguna comisión registrada.</p>
          ) : (
            <>
              <table>
                <thead>
                  <tr>
                    <th>Nombre Usuario</th>
                    <th>Nombre Usuario Referido</th>
                    <th>Nombre Producto</th>
                    <th>Monto Comisión</th>
                    <th>Fecha Comisión</th>
                  </tr>
                </thead>
                <tbody>
                  {currentItems.map((comision) => (
                    <tr key={comision["ID Comision"]}>
                      <td>{comision["Nombre Usuario Referente"]}</td>
                      <td>{comision["Nombre Usuario Referido"]}</td>
                      <td>{comision["Nombre Producto"]}</td>
                      <td>
                        ${parseFloat(comision["Monto Comision"]).toFixed(0)}
                      </td>
                      <td>
                        {new Date(
                          comision["Fecha Comision"]
                        ).toLocaleDateString()}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="pagination">
                {[
                  ...Array(
                    Math.ceil(filteredComisiones.length / itemsPerPage)
                  ).keys(),
                ].map((number) => (
                  <a
                    href="#"
                    key={number + 1}
                    onClick={(e) => handlePageChange(e, number + 1)}
                    className={currentPage === number + 1 ? "active" : ""}
                  >
                    {number + 1}
                  </a>
                ))}
              </div>
            </>
          )}
        </div>
        <div className="containerR">
          <h2 className="h2C">
            ¿Cómo generar mi enlace de invitación y su funcionamiento?
          </h2>
          <p>
            Para generar un enlace de invitación, debes darle click al boton de
            abajo "Generar enlace de invitación" y compartir dicho enlace con el
            usuario que vas a referir. El usuario referido a la hora de
            registrarse deberá usar tu enlace de invitación, este enlace tiene
            una duración de 1 hora. Al hacer esto, estarás generando un saldo
            extra por comisión dependiendo de las ventas del usuario que hayas
            referido. Puedes tener un máximo de 2 usuarios referidos a la vez.
          </p>
          <br></br>
          <div className="generate-code-container">
            <button
              onClick={generateInvitationLink}
              className="generate-code-button"
            >
              Generar enlace de invitación
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReferidosComisiones;
