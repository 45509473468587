import React, { useState, useEffect, useContext } from "react";
import { jwtDecode } from "jwt-decode";
import { AuthContext } from "../App";
import Swal from "sweetalert2";
import "../styles/detalleVentas.css";

const URL = process.env.REACT_APP_URL_BACKEND;

const DetalleVentas = () => {
  const { auth } = useContext(AuthContext);
  const [idUser, setIdUser] = useState(null);
  const [detalles, setDetalles] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const detallesPerPage = 10;

  useEffect(() => {
    if (auth) {
      try {
        const decodedToken = jwtDecode(auth);
        setIdUser(decodedToken.id);
      } catch (error) {
        console.error("Error decoding token:", error);
      }
    }
  }, [auth]);

  useEffect(() => {
    if (idUser && auth) {
      fetch(`${URL}/historial-compras`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth}`,
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Error en la respuesta del servidor");
          }
          return response.json();
        })
        .then((data) => {
          setDetalles(data);
        })
        .catch((error) => console.error("Error:", error));
    }
  }, [idUser, auth]);

  const handleRowClick = async (idVenta) => {
    if (!idVenta) {
      console.error("ID de venta no proporcionado");
      return;
    }

    try {
      const response = await fetch(
        `${URL}/detalle-venta/${idVenta}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${auth}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error(
          `Error en la respuesta del servidor: ${response.statusText}`
        );
      }

      const data = await response.json();

      const detallesArray = Array.isArray(data) ? data : [data];

      const detallesCuentas = detallesArray
        .map((detalle) => {
          const correo = detalle["Correo Cuenta"] || "N/A";
          const contrasena = detalle["Contraseña Cuenta"] || "N/A";
          const perfil = detalle["Nombre Perfil"] || "Cuenta Completa";
          return `
          <p><strong>Correo:</strong> ${correo}</p>
          <p><strong>Contraseña:</strong> ${contrasena}</p>
          <p><strong>Perfil:</strong> ${perfil}</p>
        `;
        })
        .join("<hr>");

      const fechaCompra = new Date(detallesArray[0]["Fecha Venta"]);
      if (isNaN(fechaCompra.getTime())) {
        throw new Error("Fecha de compra no válida");
      }

      const fechaLimite = new Date(fechaCompra);
      fechaLimite.setDate(fechaCompra.getDate() + 30);

      const precioUnitario = parseFloat(detallesArray[0]["Precio Unitario"]);

      Swal.fire({
        title: "Detalles de la Venta",
        html: `
          <div class="alert-content">
            <p><strong>Fecha de Compra:</strong> ${fechaCompra.toLocaleDateString()}</p>
            <p><strong>Producto:</strong> ${detallesArray[0]["Producto"]}</p>
            ${detallesCuentas}
            <p><strong>Cantidad:</strong> ${detallesArray[0]["Cantidad"]}</p>
            <p><strong>Precio:</strong> ${
              isNaN(precioUnitario) ? "N/A" : precioUnitario.toFixed(2)
            }</p>
            <p><strong>Fecha Límite:</strong> ${
              isNaN(fechaLimite.getTime())
                ? "N/A"
                : fechaLimite.toLocaleDateString()
            }</p>
            <p style="text-align: center; font-style: italic;">
              Gracias por depositar tu confianza con nosotros, cualquier inquietud puedes ponerte en contacto de inmediato.
            </p>
          </div>
        `,
        icon: "info",
        customClass: {
          htmlContainer: "alert-content",
        },
      });
    } catch (error) {
      console.error("Error al obtener los detalles de la venta:", error);
      Swal.fire({
        title: "Error",
        text: `No se pudieron obtener los detalles de la venta. Error: ${error.message}`,
        icon: "error",
      });
    }
  };

  const filteredDetalles = detalles.filter((detalle) => {
    const lowerCaseTerm = searchTerm.toLowerCase();
    return (
      detalle.nombre_producto.toLowerCase().includes(lowerCaseTerm) ||
      detalle.fecha_venta.toLowerCase().includes(lowerCaseTerm) ||
      detalle.cantidad.toString().includes(lowerCaseTerm) ||
      detalle.precio_unitario.toString().includes(lowerCaseTerm) ||
      detalle.subtotal.toString().includes(lowerCaseTerm)
    );
  });

  const indexOfLastDetalle = currentPage * detallesPerPage;
  const indexOfFirstDetalle = indexOfLastDetalle - detallesPerPage;
  const currentDetalles = filteredDetalles.slice(
    indexOfFirstDetalle,
    indexOfLastDetalle
  );

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className="usuarios-wrapper">
      <div className="usuarios-container">
        <div className="search-bar">
          <h2>Historial de Compras</h2>
          <input
            type="text"
            placeholder="Buscar..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="search-barDV"
          />
        </div>

        <table>
          <thead>
            <tr>
              <th>Fecha de Venta</th>
              <th>Producto</th>
              <th>Cantidad</th>
              <th>Precio Unitario</th>
              <th>Subtotal</th>
            </tr>
          </thead>
          <tbody>
            {currentDetalles.length > 0 ? (
              currentDetalles.map((detalle) => {
                const precioUnitario = Number(detalle.precio_unitario);
                const subtotal = Number(detalle.subtotal);

                return (
                  <tr
                    key={detalle.id_historial}
                    onClick={() => handleRowClick(detalle.id_historial)}
                  >
                    <td>
                      {new Date(detalle.fecha_venta).toLocaleDateString()}
                    </td>
                    <td>{detalle.nombre_producto}</td>
                    <td>{detalle.cantidad}</td>
                    <td>
                      $
                      {isNaN(precioUnitario)
                        ? "N/A"
                        : precioUnitario.toFixed(0)}
                    </td>
                    <td>${isNaN(subtotal) ? "N/A" : subtotal.toFixed(0)}</td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan="5">No hay compras registradas.</td>
              </tr>
            )}
          </tbody>
        </table>

        <div className="pagination">
          {Array.from(
            { length: Math.ceil(filteredDetalles.length / detallesPerPage) },
            (_, index) => (
              <button
                key={index + 1}
                onClick={() => paginate(index + 1)}
                className={currentPage === index + 1 ? "active" : ""}
              >
                {index + 1}
              </button>
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default DetalleVentas;
