// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.containerComprar {
  width: 100%;
  margin: 20px auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.pF {
  text-align: center;
  padding: 0 15px;
}

.iF {
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
}

.iF img {
  max-width: 100%;
  height: auto;
}

.pSF {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 25px;
}

.containerCondiciones {
  width: 100%;
  margin: 0 auto;
  padding: 20px;
}

.terms-info {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 18px;
  color: #333;
}

.terms-info h2 {
  margin-bottom: 20px;
  color: #143d59;
  font-size: 24px;
}

.terms-info h3 {
  margin-top: 20px;
  color: #143d59;
  font-size: 20px;
}

.terms-info p {
  margin-bottom: 15px;
  line-height: 1.6;
}

.terms-info ul {
  margin-bottom: 15px;
  list-style-type: disc;
  padding-left: 20px;
}

.terms-info ul li {
  margin-bottom: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/styles/comprarSaldo.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,iBAAiB;EACjB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,sBAAsB;AACxB;;AAEA;EACE,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,WAAW;EACX,cAAc;EACd,aAAa;EACb,uBAAuB;AACzB;;AAEA;EACE,eAAe;EACf,YAAY;AACd;;AAEA;EACE,yCAAyC;EACzC,eAAe;AACjB;;AAEA;EACE,WAAW;EACX,cAAc;EACd,aAAa;AACf;;AAEA;EACE,yCAAyC;EACzC,eAAe;EACf,WAAW;AACb;;AAEA;EACE,mBAAmB;EACnB,cAAc;EACd,eAAe;AACjB;;AAEA;EACE,gBAAgB;EAChB,cAAc;EACd,eAAe;AACjB;;AAEA;EACE,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,mBAAmB;EACnB,qBAAqB;EACrB,kBAAkB;AACpB;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":[".containerComprar {\n  width: 100%;\n  margin: 20px auto;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex-direction: column;\n}\n\n.pF {\n  text-align: center;\n  padding: 0 15px;\n}\n\n.iF {\n  width: 100%;\n  margin: 0 auto;\n  display: flex;\n  justify-content: center;\n}\n\n.iF img {\n  max-width: 100%;\n  height: auto;\n}\n\n.pSF {\n  font-family: Arial, Helvetica, sans-serif;\n  font-size: 25px;\n}\n\n.containerCondiciones {\n  width: 100%;\n  margin: 0 auto;\n  padding: 20px;\n}\n\n.terms-info {\n  font-family: Arial, Helvetica, sans-serif;\n  font-size: 18px;\n  color: #333;\n}\n\n.terms-info h2 {\n  margin-bottom: 20px;\n  color: #143d59;\n  font-size: 24px;\n}\n\n.terms-info h3 {\n  margin-top: 20px;\n  color: #143d59;\n  font-size: 20px;\n}\n\n.terms-info p {\n  margin-bottom: 15px;\n  line-height: 1.6;\n}\n\n.terms-info ul {\n  margin-bottom: 15px;\n  list-style-type: disc;\n  padding-left: 20px;\n}\n\n.terms-info ul li {\n  margin-bottom: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
