import React, { useState, useEffect } from "react";
import "../styles/ventas.css";
import Swal from "sweetalert2";

const URL = process.env.REACT_APP_URL_BACKEND;

const Ventas = () => {
  const [ventas, setVentas] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedVenta, setSelectedVenta] = useState(null);
  const ventasPerPage = 10;

  useEffect(() => {
    const fetchVentas = async () => {
      try {
        const response = await fetch(`${URL}/api/ventas`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });

        if (!response.ok) {
          throw new Error("No se pudo obtener la lista de ventas");
        }

        const data = await response.json();

        setVentas(data);
      } catch (error) {
        console.error("Error al obtener las ventas:", error);
      }
    };

    fetchVentas();
  }, []);

  const handleRowClick = async (idVenta) => {
    try {
      const response = await fetch(
        `${URL}/detalle-venta/${idVenta}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Error al obtener los detalles de la venta");
      }

      const data = await response.json();

      const fechaCompra = new Date(data["Fecha Venta"]);
      if (isNaN(fechaCompra.getTime())) {
        throw new Error("Fecha de compra no válida");
      }

      const fechaLimite = new Date(fechaCompra);
      fechaLimite.setDate(fechaCompra.getDate() + 30);

      Swal.fire({
        title: "Detalles de la Venta",
        html: `
          <div class="alert-content">
            <p><strong>Fecha de Venta:</strong> ${new Date(
              data["Fecha Venta"]
            ).toLocaleDateString()}</p>
            <p><strong>Producto:</strong> ${data["Producto"]}</p>
            <p><strong>Correo Cuenta:</strong> ${data["Correo Cuenta"]}</p>
            <p><strong>Contraseña Cuenta:</strong> ${
              data["Contraseña Cuenta"]
            }</p>
            <p><strong>Nombre Perfil:</strong> ${
              data["Nombre Perfil"] || "Cuenta completa"
            }</p>
            <p><strong>Cantidad:</strong> ${data["Cantidad"]}</p>
                                    <p><strong>Total Venta:</strong> ${
                                      data["Precio Unitario"]
                                    }</p>
            <p><strong>Fecha Límite:</strong> ${
              isNaN(fechaLimite.getTime())
                ? "N/A"
                : fechaLimite.toLocaleDateString()
            }</p>
        `,
        icon: "info",
        customClass: {
          htmlContainer: "alert-content",
        },
      });
    } catch (error) {
      console.error("Error al obtener los detalles de la venta:", error);
      Swal.fire({
        title: "Error",
        text: "No se pudieron obtener los detalles de la venta.",
        icon: "error",
      });
    }
  };

  const filteredVentas = ventas.filter((venta) => {
    const lowerCaseTerm = searchTerm.toLowerCase();
    return (
      (venta.id_usuario &&
        venta.id_usuario.toString().includes(lowerCaseTerm)) ||
      (venta.nombre_producto &&
        venta.nombre_producto.toLowerCase().includes(lowerCaseTerm)) ||
      (venta.correo && venta.correo.toLowerCase().includes(lowerCaseTerm)) ||
      (venta.nombre_usuario &&
        venta.nombre_usuario.toLowerCase().includes(lowerCaseTerm)) ||
      (venta.fecha_venta &&
        new Date(venta.fecha_venta)
          .toLocaleDateString()
          .includes(lowerCaseTerm)) ||
      (venta.total_venta &&
        venta.total_venta.toString().includes(lowerCaseTerm)) ||
      (venta.id_referido_por
        ? venta.id_referido_por.toString().includes(lowerCaseTerm)
        : false)
    );
  });

  const indexOfLastVenta = currentPage * ventasPerPage;
  const indexOfFirstVenta = indexOfLastVenta - ventasPerPage;
  const currentVentas = filteredVentas.slice(
    indexOfFirstVenta,
    indexOfLastVenta
  );

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className="usuarios-wrapper">
      <div className="usuarios-container">
        <div className="search-bar">
          <h2>Historial Ventas Realizadas</h2>
          <input
            type="text"
            placeholder="Buscar..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>

        <table>
          <thead>
            <tr>
              <th>ID Venta</th>
              <th>Usuario</th>
              <th>Correo</th>
              <th>Nombre del Producto</th>
              <th>Fecha de Venta</th>
              <th>Total Venta</th>
              <th>ID_R</th>
              <th>Tipo de Producto</th>
              <th>Comisión</th>
            </tr>
          </thead>
          <tbody>
            {currentVentas.map((venta) => (
              <tr
                key={venta.id_venta}
                onClick={() => handleRowClick(venta.id_venta)}
              >
                <td>{venta.id_venta || "N/A"}</td>
                <td>{venta.nombre_usuario || "N/A"}</td>
                <td>{venta.correo || "N/A"}</td>
                <td>{venta.nombre_producto || "N/A"}</td>
                <td>
                  {venta.fecha_venta
                    ? new Date(venta.fecha_venta).toLocaleDateString()
                    : "N/A"}
                </td>
                <td>{venta.total_venta || "N/A"}</td>
                <td>{venta.id_referido_por || "N/A"}</td>
                <td>{venta.tipo_producto || "N/A"}</td>
                <td>{venta.comision || "N/A"}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="pagination">
          {Array.from(
            { length: Math.ceil(filteredVentas.length / ventasPerPage) },
            (_, index) => (
              <button
                key={index + 1}
                onClick={() => paginate(index + 1)}
                className={currentPage === index + 1 ? "active" : ""}
              >
                {index + 1}
              </button>
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default Ventas;
