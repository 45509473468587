import React, { useState, useEffect } from "react";
import "../styles/ranking.css";

const URL = process.env.REACT_APP_URL_BACKEND;

const Ranking = () => {
  const [rankings, setRankings] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const rankingsPerPage = 10;

  useEffect(() => {
    const fetchRankings = async () => {
      try {
        const response = await fetch(`${URL}/api/ranking`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });

        if (!response.ok) {
          throw new Error("No se pudo obtener la lista de rankings");
        }

        const data = await response.json();
        setRankings(data);
      } catch (error) {
        console.error("Error al obtener los rankings:", error);
      }
    };

    fetchRankings();
  }, []);

  const filteredRankings = rankings.filter((ranking) =>
    ranking.nombre_usuario.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const indexOfLastRanking = currentPage * rankingsPerPage;
  const indexOfFirstRanking = indexOfLastRanking - rankingsPerPage;
  const currentRankings = filteredRankings.slice(
    indexOfFirstRanking,
    indexOfLastRanking
  );

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const getMedal = (posicion) => {
    switch (posicion) {
      case 1:
        return "🥇";
      case 2:
        return "🥈";
      case 3:
        return "🥉";
      default:
        return "";
    }
  };

  return (
    <div className="ranking-wrapper">
      <div className="ranking-container">
        <div className="search-bar">
          <h2>Top Usuarios de Ventas del mes</h2>
          <input
            type="text"
            placeholder="Buscar por nombre..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>

        <table>
          <thead>
            <tr>
              <th>Posición</th>
              <th>Nombre Usuario</th>
              <th>Total Ventas</th>
              <th>Premio</th>
            </tr>
          </thead>
          <tbody>
            {currentRankings.map((ranking) => (
              <tr key={ranking.id_usuario}>
                <td>{ranking.posicion}</td>
                <td>{ranking.nombre_usuario}</td>
                <td>{ranking.total_ventas}</td>
                <td>{getMedal(ranking.posicion)}</td>
              </tr>
            ))}
          </tbody>
        </table>

        <div className="pagination">
          {Array.from(
            { length: Math.ceil(filteredRankings.length / rankingsPerPage) },
            (_, index) => (
              <button
                key={index + 1}
                onClick={() => paginate(index + 1)}
                className={currentPage === index + 1 ? "active" : ""}
              >
                {index + 1}
              </button>
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default Ranking;
