import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import Swal from "sweetalert2";
import "../styles/register.css";
import logo from "../imgs/logoNav.jpg";

const URL = process.env.REACT_APP_URL_BACKEND;

const Register = () => {
  const [formData, setFormData] = useState({
    nombre_usuario: "",
    email: "",
    numero_celular: "",
    password: "",
    confirmPassword: "",
    token_invitacion: "",
  });

  const navigate = useNavigate();

  useEffect(() => {
    Swal.fire({
      icon: "info",
      title: "Recomendación",
      text: "Se recomienda registrarse con un correo al que tengas acceso, ya que se enviarán detalles importantes a esa dirección.",
      confirmButtonText: "Aceptar",
    });
  }, []);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const {
      nombre_usuario,
      email,
      numero_celular,
      password,
      confirmPassword,
      token_invitacion,
    } = formData;

    if (!nombre_usuario) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "El nombre de usuario es obligatorio",
        confirmButtonText: "Aceptar",
      });
      return;
    }

    if (password !== confirmPassword) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Las contraseñas no coinciden",
        confirmButtonText: "Aceptar",
      });
      return;
    }

    try {
      const response = await fetch(`${URL}/api/register`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          nombre_usuario,
          email,
          numero_celular,
          password,
          token_invitacion,
        }),
      });

      const textResponse = await response.text();
      console.log(textResponse);

      try {
        const data = JSON.parse(textResponse);

        if (response.ok) {
          Swal.fire({
            icon: "success",
            title: "Registro exitoso",
            text: "¡Te has registrado correctamente!",
            confirmButtonText: "Aceptar",
          }).then(() => {
            navigate("/login");
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: data.message || "Error durante el registro",
            confirmButtonText: "Aceptar",
          });
        }
      } catch (error) {
        console.error("Error al parsear JSON:", error);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Respuesta del servidor no válida",
          confirmButtonText: "Aceptar",
        });
      }
    } catch (error) {
      console.error("Error durante el registro:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Error durante el registro",
        confirmButtonText: "Aceptar",
      });
    }
  };

  return (
    <div className="register-container">
      <div className="divImg">
        <Link to="/">
          <img src={logo} alt="Logo" className="navbar-logo-img" />
        </Link>
      </div>
      <h2>Registrarse en instashop</h2>
      <hr className="linea" />
      <form className="register-form" onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="nombre_usuario">Nombre de usuario</label>
          <input
            type="text"
            id="nombre_usuario"
            name="nombre_usuario"
            placeholder="Nombre de usuario"
            value={formData.nombre_usuario}
            onChange={handleChange}
            required
            autoComplete="off"
          />
        </div>
        <div className="form-group">
          <label htmlFor="email">Correo electrónico</label>
          <input
            type="email"
            id="email"
            name="email"
            placeholder="Correo electrónico"
            value={formData.email}
            onChange={handleChange}
            required
            autoComplete="off"
          />
        </div>
        <div className="form-group">
          <label htmlFor="numero_celular">Número de Celular</label>
          <input
            type="text"
            id="numero_celular"
            name="numero_celular"
            placeholder="Número de Celular"
            value={formData.numero_celular}
            onChange={handleChange}
            required
            autoComplete="off"
          />
        </div>
        <div className="form-group">
          <label htmlFor="password">Contraseña</label>
          <input
            type="password"
            id="password"
            name="password"
            placeholder="Contraseña"
            value={formData.password}
            onChange={handleChange}
            required
            autoComplete="off"
          />
        </div>
        <div className="form-group">
          <label htmlFor="confirmPassword">Confirmar Contraseña</label>
          <input
            type="password"
            id="confirmPassword"
            name="confirmPassword"
            placeholder="Confirmar Contraseña"
            value={formData.confirmPassword}
            onChange={handleChange}
            required
            autoComplete="off"
          />
        </div>
        <div className="form-group">
          <label htmlFor="token_invitacion">
            Token de Invitación (opcional)
          </label>
          <input
            type="text"
            id="token_invitacion"
            name="token_invitacion"
            placeholder="Token de Invitación"
            value={formData.token_invitacion}
            onChange={handleChange}
            autoComplete="off"
          />
        </div>
        <button type="submit" className="btn-submit">
          Registrarse
        </button>
      </form>
      <div className="register-options">
        <p className="pColor">
          ¿Ya tienes cuenta? <Link to="/login">Iniciar sesión</Link>
        </p>
      </div>
    </div>
  );
};

export default Register;
